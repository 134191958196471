import { status } from "skin/event/status";
import table from "./table";

const sensor = (obj, ms, story, data, d, action) => {
  console.log("[Ячейки - Мастер] событие", obj);
  switch (obj.type) {
    case "table":
      if (obj.action === "row") {
        return table(obj, ms, story, data, d, action);
      }
      break;
    default:
      return status.action;
  }
};

export default sensor;
