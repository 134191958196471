import { status } from "skin/event/status";
import { space } from "skin/event";

function table(obj, ms, story, data, d, action) {
  // возвращаем типовое поведение для всех элементов кроме таблицы агрегатов
  if (obj.table !== 'tnk_aggregateList') return status.action;

  // Для списка агрегатов создаем перехватчик, который направляет в нужную форму
  const key = data?.s?.values?.table?.tnk_aggregateList?.list;
  const aId = key.find((el) => el.key === obj.key).list?.[1];
  const row = story.current.table.tnk_aggregateList.row;

  if (obj.key === row ) {
    const o = {
      type: "form",
      title: "Агрегат",
      data: {
        code: "tnk_aggregate",
        info: [aId],
      },
    };
    space(o, true);
    return status.ignore;
  }
}
export default table;
