import { status } from "skin/event/status";
import { space } from "skin/event";

function table(obj, ms, story, data, d, action) {
  const key = data?.s?.values?.table?.tnk_compressorList?.list;
  const cprId = key.find((el) => el.key === obj.key).list?.[1];

  const row = story.current.table.tnk_compressorList.row;
  if (obj.key === row) {
    const o = {
      type: "form",
      title: "Компрессор",
      data: {
        code: "tnk_compressor",
        info: [cprId],
      },
    };
    space(o, true);
    return status.ignore;
  }
}
export default table;
