import { status } from 'skin/event/status'
import act from './act'
import table from './table'

const building = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj)
	switch (obj.type) {
		case 'action':
            return act(obj, ms, story, data, d, action)
		case "table":
			// этот case для перехватчика в форму tnk_aggregate
			if (obj.action === "row") {
				return table(obj, ms, story, data, d, action);
			}
			break;
		default:
			return status.action
	}
}

export default building